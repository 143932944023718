.fromIcon {
  position: relative;

  $width: 12px;
  height: calc(100% - 40px);

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    border-left: 2px solid var(--chakra-colors-gray-4);
    right: -4px;
    border-radius: 12px;
  }
}

.toIcon {
  position: relative;

  $width: 12px;
  height: calc(100% - 40px);

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    border-right: 2px solid var(--chakra-colors-gray-4);
    left: -4px;
    border-radius: 12px;
  }
}

.divider {
  transition: border-color 0.3s ease;
}

.trade {
  cursor: pointer;

  &:hover {
    .divider {
      border-color: var(--chakra-colors-white);
    }
  }
}

.trade.selected {
  .divider {
    border-color: var(--chakra-colors-orange);
  }

  svg {
    path {
      fill:  var(--chakra-colors-orange);
    }
  }
}
